import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

export const  GetThirdAccountFirst = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/TrafficReport/GetThirdAccountFirst`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const  ThirdAccountAdd = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/TrafficReport/ThirdAccountAdd`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const  ThirdAccountDel = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/TrafficReport/ThirdAccountDel`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询可上报数据的运单
export const GetBills = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/TrafficReport/GetBills`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 上报车辆
export const ReportCar = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/TrafficReport/ReportCar`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 上报司机
export const ReportDriver = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/TrafficReport/ReportDriver`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 上报运单
export const ReportWaybill = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/TrafficReport/ReportWaybill`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 上报资金
export const ReportCaptial = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/TrafficReport/ReportCaptial`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}
// 批量上报
export const ReportBatch = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/TrafficReport/ReportBatch`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取交通厅服务商列表
export const GetThirdAccountList = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/TrafficReport/GetThirdAccountList`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}